import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Button, EasyFilterView, Loading, PageHeader, Select } from '@ui';
import {
  useGetAnalyticProjectsForLastProjectsQuery,
  useGetAnalyticsProjectsListQuery,
  useGetSingleAnalyticProjectQuery,
} from '@app/store/api/analytics-projects.api';
import useTranslation from '@app/hooks/use-translation';
import { useGetProjectMetricsListQuery } from '@app/store/api/metrics.api';
import { Filter, Range } from '@app/components/ui/easy-filter/types';
import { AllStatusProject, GroupSharedStatus } from '@app/interfaces/analytics';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { useCreateLiveReportMutation } from '@app/store/api/live-reports.api';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@app/store/store';
import { useUpdateUserSettingsMutation } from '@app/store/api/user-settings.api';
import SkeletonPageLoading from '@app/components/ui/skeleton-page-loading';
import { TableMetricRecords } from '@app/components';
import { useGetChatProjectMetricsListQuery } from '@app/store/api/chat-api/chat-metric.api';
import {
  useGetChatAnalyticProjectsForLastProjectsQuery,
  useGetChatGroupQuery,
  useGetSingleChatProjectQuery,
} from '@app/store/api/chat-api/chat-analytic.api';
import { usePermissionCheck } from '@app/hooks/use-permission';
import { userPermissions } from '@app/utils/user-permissions';
import { useCreateChatLiveReportMutation } from '@app/store/api/chat-api/live-reports.api';
import { FilterSearchTable } from '@app/components/filter-search-table';
import { TableColumn } from '@app/components/rubber-table/table.types';
import { TableFilter, TableItem } from '@app/components/table/table.type';
import { Oscilogram } from '@app/components/ui/icons/icons-list';
import {
  FullTextSearchRequestType,
  FullTextSearchResponseType,
} from '@app/interfaces/full-text-search.type';
import { useGetExtAndUsrModelsQuery } from '@app/store/api/asr_model.api';
import { useGetChannelsQuery } from '@app/store/api/get-channels-info.api';
import {
  useLazyGetFullWordSearchByProjectChatsQuery,
  useLazyGetFullWordSearchByProjectQuery,
} from '@app/store/api/get-full-text-search.api';
import { useGetChatRolesQuery } from '@app/store/api/chat-api/get-chat-roles.api';

export interface GetFilterParams {
  offset: number | string;
  limit: number;
  filter: Filter & Range;
  sortBy?: string;
  sortDesc?: boolean;
  preset_mode?: boolean;
}
const DEFAULT_RECORDS_LIMIT = 10;
const DEFAULT_RECORDS_OFFSET = 0;

type LastProjectRecordsPagePropsType = {
  currentTemplate: 'chat' | 'voice';
};

const LastProjectRecordsPage: FC<LastProjectRecordsPagePropsType> = React.memo((props) => {
  const { currentTemplate } = props;
  const { t } = useTranslation('pages.recordsByAnalytics');
  const [localLoading, changeLocalLoading] = useState(false);
  const navigate = useNavigate();
  const { userSettings } = useAppSelector((state) => state.userSettings);
  const currentProjectIdByTemplate =
    currentTemplate === 'chat' ? userSettings?.lastProjectChats : userSettings?.lastProjectRecords;

  // data for search
  const { t: filterSearchT } = useTranslation('components.filterSearch');

  const [fullWordTextSearchRequestData, setFullWordTextSearchRequestData] = useState<
    Partial<FullTextSearchRequestType>
  >({ offset: 0, limit: 10, project_id: currentProjectIdByTemplate || undefined });
  const [fullWordSearchTable, setFullWordSearchTable] = useState<FullTextSearchResponseType>();
  const [getFullTextTableDataVoice, { isLoading: searchLoadingRec }] =
    useLazyGetFullWordSearchByProjectQuery();
  const [getFullTextTableDataChat, { isLoading: searchLoadingChat }] =
    useLazyGetFullWordSearchByProjectChatsQuery();
  const searchLoading = searchLoadingChat || searchLoadingRec;
  const getFullTextTableData = { chat: getFullTextTableDataChat, voice: getFullTextTableDataVoice };
  const { data: channels } = useGetChannelsQuery();
  const { data: models } = useGetExtAndUsrModelsQuery();
  const { data: chatRoles } = useGetChatRolesQuery(undefined, {
    skip: currentTemplate === 'voice',
  });

  // data for search

  const DEFAULT_FILTER_SETUP: GetFilterParams = useMemo(
    () => ({
      offset: DEFAULT_RECORDS_OFFSET,
      limit: userSettings?.tablesLimit?.lastProjectRecords || DEFAULT_RECORDS_LIMIT,
      filter: {
        range: {
          type: 'n',
          parameters: [],
        },
        filter: [],
      },
    }),
    [userSettings?.tablesLimit?.lastProjectRecords],
  );

  const [filterParams, changeFilterParams] = useState<GetFilterParams>(DEFAULT_FILTER_SETUP);

  useEffect(() => {
    userSettings?.tablesLimit?.lastProjectRecords &&
      changeFilterParams((prev) => ({
        ...prev,
        limit: userSettings?.tablesLimit?.lastProjectRecords as number,
      }));
  }, [userSettings?.tablesLimit?.lastProjectRecords]);

  //api
  const [updateUserSettings] = useUpdateUserSettingsMutation();
  //
  const { data: analyticsProjectsGroupsVoice, isLoading: allProjectsLoadingVoice } =
    useGetAnalyticsProjectsListQuery(undefined, { skip: currentTemplate === 'chat' });
  const { data: analyticsProjectsGroupsChat, isLoading: allProjectsLoadingChat } =
    useGetChatGroupQuery(undefined, { skip: currentTemplate === 'voice' });

  const analyticsProjectsGroups = useMemo(
    () => ({
      voice: analyticsProjectsGroupsVoice,
      chat: analyticsProjectsGroupsChat,
    }),
    [analyticsProjectsGroupsChat, analyticsProjectsGroupsVoice],
  );
  const allProjectsLoading = allProjectsLoadingVoice || allProjectsLoadingChat;
  //
  const { data: analyticsProjectsVoice, isLoading: projectLoadingForLastVoice } =
    useGetAnalyticProjectsForLastProjectsQuery(undefined, { skip: currentTemplate === 'chat' });
  const { data: analyticsProjectsChat, isLoading: projectLoadingForLastChat } =
    useGetChatAnalyticProjectsForLastProjectsQuery(undefined, {
      skip: currentTemplate === 'voice',
    });

  const analyticsProjects = useMemo(
    () => ({ chat: analyticsProjectsChat, voice: analyticsProjectsVoice }),
    [analyticsProjectsChat, analyticsProjectsVoice],
  );

  const projectLoadingForLast = projectLoadingForLastVoice || projectLoadingForLastChat;
  //
  const { data: projectVoice, isLoading: projectLoadingVoice } = useGetSingleAnalyticProjectQuery(
    { id: currentProjectIdByTemplate || '' },
    { skip: !currentProjectIdByTemplate || currentTemplate === 'chat' },
  );
  const { data: projectChat, isLoading: projectLoadingChat } = useGetSingleChatProjectQuery(
    { id: currentProjectIdByTemplate || '' },
    { skip: !currentProjectIdByTemplate || currentTemplate === 'voice' },
  );
  const projectLoading = projectLoadingChat || projectLoadingVoice;
  const project = { chat: projectChat, voice: projectVoice };
  //
  const { data: metricListChat, isLoading: chatMetricListLoading } =
    useGetChatProjectMetricsListQuery(
      { project_id: project[currentTemplate || 'voice']?.project_id || '' },
      { skip: !project[currentTemplate || 'voice']?.project_id || currentTemplate === 'voice' },
    );
  const { data: metricListVoice, isLoading: projectMetricLoadingVoice } =
    useGetProjectMetricsListQuery(
      {
        project_id: project[currentTemplate || 'voice']?.project_id || '',
      },
      { skip: !project[currentTemplate || 'voice']?.project_id || currentTemplate === 'chat' },
    );
  const metricList = {
    chat: metricListChat,
    voice: metricListVoice,
  };
  const projectMetricLoading = chatMetricListLoading || projectMetricLoadingVoice;
  const [createVoiceLiveReportMutation] = useCreateLiveReportMutation();
  const [createChatLiveReportMutation] = useCreateChatLiveReportMutation();
  const createLiveReportMutation = {
    chat: createChatLiveReportMutation,
    voice: createVoiceLiveReportMutation,
  };

  const reportCanBeEdited = usePermissionCheck({
    tag: userPermissions.action.actionEditLiveReport,
    permissions: 'action',
  });

  useEffect(() => {
    if (currentProjectIdByTemplate) return;
    changeLocalLoading(true);

    const project_id_default =
      analyticsProjectsGroups[currentTemplate || 'voice']?.find((i) => i.is_default)?.projects?.[0]
        ?.project_id ||
      analyticsProjectsGroups[currentTemplate || 'voice']?.[0]?.projects?.[0].project_id;

    if (!currentProjectIdByTemplate) {
      const data =
        currentTemplate === 'chat'
          ? { ...userSettings, lastProjectChats: project_id_default }
          : { ...userSettings, lastProjectRecords: project_id_default };
      project_id_default && updateUserSettings(data);
    }
    if (analyticsProjects[currentTemplate || 'voice'] && currentProjectIdByTemplate) {
      const condition = analyticsProjects[currentTemplate || 'voice']?.find(
        (item) => item.project_id === currentProjectIdByTemplate,
      )?.name;

      if (!condition) {
        const data =
          currentTemplate === 'chat'
            ? { ...userSettings, lastProjectChats: project_id_default }
            : { ...userSettings, lastProjectRecords: project_id_default };
        project_id_default && updateUserSettings(data);
      }
    }
    setTimeout(() => {
      changeLocalLoading(false);
    }, 500);
  }, [
    analyticsProjects,
    analyticsProjectsGroups,
    currentProjectIdByTemplate,
    currentTemplate,
    updateUserSettings,
    userSettings,
  ]);

  const getOptionsSelect = () => {
    if (!analyticsProjects[currentTemplate || 'voice']?.length) return [];
    return (analyticsProjects?.[currentTemplate || 'voice'] || []).map(({ name, project_id }) => ({
      title: name,
      value: project_id,
    }));
  };

  const changeProjectSelect = useCallback(
    (value: string) => {
      changeLocalLoading(true);
      setFullWordTextSearchRequestData((prev) => ({ ...prev, project_id: value }));
      setFullWordSearchTable(undefined);
      changeFilterParams(DEFAULT_FILTER_SETUP);
      currentTemplate === 'chat'
        ? updateUserSettings({ ...userSettings, lastProjectChats: value })
            .unwrap()
            .finally(() => setTimeout(() => changeLocalLoading(false), 600))
        : updateUserSettings({ ...userSettings, lastProjectRecords: value })
            .unwrap()
            .finally(() => setTimeout(() => changeLocalLoading(false), 600));
    },
    [DEFAULT_FILTER_SETUP, currentTemplate, updateUserSettings, userSettings],
  );

  function getFilteringKeys() {
    return metricList[currentTemplate || 'voice']?.map((metric) => ({
      id: metric.metric_id,
      title: metric.name,
      type_value: metric.result_value_type,
    }));
  }

  function cancelFilter() {
    changeFilterParams((prev) => ({
      ...prev,
      filter: DEFAULT_FILTER_SETUP.filter,
    }));
  }
  const getSaveBtnDisabled = () => {
    if (
      filterParams.filter.filter.length === 0 &&
      filterParams.filter.range.parameters.length === 0
    )
      return true;
    if (
      project[currentTemplate || 'voice']?.status !== AllStatusProject.CREATED &&
      project[currentTemplate || 'voice']?.status !== AllStatusProject.PAUSE
    )
      return true;
    return (
      !project[currentTemplate || 'voice']?.owner &&
      project[currentTemplate || 'voice']?.shared === GroupSharedStatus.VIEW
    );
  };
  function handleFilterChange(newFilter: Filter & Range) {
    changeFilterParams((prev) => ({ ...prev, filter: newFilter }));
    if (!fullWordSearchTable) return;
    const combinedData = { ...fullWordTextSearchRequestData, filter: newFilter };
    setFullWordTextSearchRequestData(combinedData);
    getFullSearchTable(combinedData);
  }
  function createLiveReport() {
    if (!project) return;
    const result = {
      name: `${project[currentTemplate || 'voice']?.name || ''}`,
      description: project[currentTemplate || 'voice']?.description || '',
      filter: filterParams.filter,
      project_id: project[currentTemplate || 'voice']?.project_id || '',
    };
    createLiveReportMutation[currentTemplate || 'voice'](result)
      .unwrap()
      .then((data) => {
        const pashEnter = {
          chat: navigationRoutes.chatReports,
          voice: navigationRoutes.reports,
        };
        navigate(
          `/${pashEnter[currentTemplate || 'voice']}/${data.live_report_id}/${
            navigationRoutes.settings
          }`,
          {
            state: navigationRoutes.reports,
          },
        );
      });
  }

  const getTitleFilter = <h2 className="text-1color font-bold">{t('filter.title')}</h2>;

  const getFooterFilter = (
    <div className="flex items-center gap-[10px]">
      <Button
        data-id="new-report"
        label={
          process.env.REACT_APP_THEME === 'rtk'
            ? t('filter.buttons.create_report_rtk')
            : t('filter.buttons.create_report')
        }
        onClick={() => createLiveReport()}
        disabled={(() => {
          if (!reportCanBeEdited) {
            return (
              !project[currentTemplate || 'voice']?.owner &&
              project[currentTemplate || 'voice']?.shared === GroupSharedStatus.VIEW
            );
          } else return true;
        })()}
      />
      <Button
        fill="linked"
        label={t('filter.buttons.reset_filter')}
        onClick={() => cancelFilter()}
        disabled={getSaveBtnDisabled()}
      />
    </div>
  );

  // search

  const tableSortKeyList = useMemo(
    () => ({
      date: 'datetime',
    }),
    [],
  );

  function tableSearchChangeFilterHandler(reportTableOrderBy: TableFilter) {
    const sort =
      reportTableOrderBy && Object.keys(reportTableOrderBy).length
        ? {
            sortBy: tableSortKeyList[Object.keys(reportTableOrderBy)[0]],
            sortDesc: 0 < Object.values(reportTableOrderBy)[0],
          }
        : { sortBy: undefined, sortDesc: undefined };

    const changedObject = {
      ...fullWordTextSearchRequestData,
      ...sort,
    } as Partial<FullTextSearchRequestType>;
    setFullWordTextSearchRequestData(changedObject);
    getFullSearchTable(changedObject);
  }

  function onSubmitSearch(data) {
    const combinedData = { ...fullWordTextSearchRequestData, ...data };
    setFullWordTextSearchRequestData(combinedData);
    getFullSearchTable(combinedData);
  }

  function handleChangePage(type: 'prev' | 'next') {
    switch (type) {
      case 'next':
        setFullWordTextSearchRequestData({
          ...fullWordTextSearchRequestData,
          offset:
            (fullWordTextSearchRequestData.offset || 0) +
            (fullWordTextSearchRequestData.limit || 10),
        });
        getFullSearchTable({
          ...fullWordTextSearchRequestData,
          offset:
            (fullWordTextSearchRequestData.offset || 0) +
            (fullWordTextSearchRequestData.limit || 10),
        });
        break;
      case 'prev':
        setFullWordTextSearchRequestData({
          ...fullWordTextSearchRequestData,
          offset:
            (fullWordTextSearchRequestData.offset || 0) -
            (fullWordTextSearchRequestData.limit || 10),
        });
        getFullSearchTable({
          ...fullWordTextSearchRequestData,
          offset:
            (fullWordTextSearchRequestData.offset || 0) -
            (fullWordTextSearchRequestData.limit || 10),
        });
        break;

      default:
        break;
    }
  }
  const idKeyByTemplate = useMemo(() => ({ chat: 'chat_id', voice: 'record_id' }), []);
  const idKeyByTemplate2 = useMemo(() => ({ chat: 'chat', voice: 'record' }), []);
  const recordPathByTemplate = useMemo(
    () => ({ chat: navigationRoutes.chatProjectRecords, voice: navigationRoutes.projectRecords }),
    [],
  );
  function getFullSearchTable(data: Partial<FullTextSearchRequestType>) {
    if (!data) return;
    getFullTextTableData[currentTemplate || 'voice']({
      ...data,
      asr_model_id: data.asr_model_id === 'all' ? undefined : data.asr_model_id,
      channel: data.channel === 'all' ? undefined : data.channel,
      role: data.role === 'all' ? undefined : data.role,
    })
      .unwrap()
      .then((data) => setFullWordSearchTable(data));
  }
  const tableFullTextSearchColumns: TableColumn[] =
    currentTemplate === 'voice'
      ? [
          { index: 'date', title: filterSearchT('table.date'), maxWidth: 240, filter: true },
          { index: 'text', title: filterSearchT('table.text') },
          { index: 'channel', title: filterSearchT('table.channel'), maxWidth: 120 },
          { index: 'asr_model_id', title: filterSearchT('table.model'), maxWidth: 120 },
          { index: 'actions', title: filterSearchT('table.actions'), maxWidth: 90 },
        ]
      : [
          { index: 'date', title: filterSearchT('table.date'), maxWidth: 240, filter: true },
          { index: 'text', title: filterSearchT('table.text') },
          { index: 'crole_name', title: filterSearchT('table.crole_name'), maxWidth: 180 },
          { index: 'actions', title: filterSearchT('table.actions'), maxWidth: 90 },
        ];
  const tableFullTextSearchData = (fullWordSearchTable?.results || []).map((tableItem) => ({
    ...tableItem,
    date: currentTemplate === 'voice' ? tableItem?.record?.dt_start : tableItem?.chat?.dt_start,
    text: <div dangerouslySetInnerHTML={{ __html: tableItem.text }} title={tableItem.text} />,
    channel: tableItem?.channel?.name,
    crole_name: tableItem?.crole?.name,
    asr_model_id: models?.find((model) => model.model_id === tableItem.asr_model_id)?.name,
    actions: (
      <div className="flex w-full items-center justify-center">
        <Oscilogram
          hintTitle={filterSearchT('popup_hints.to_record_title')}
          size={22}
          className="text-3color  hover:text-action cursor-pointer transition"
          onClick={() =>
            window.open(
              `/${recordPathByTemplate[currentTemplate]}/${currentProjectIdByTemplate}/${
                tableItem[idKeyByTemplate2[currentTemplate]][idKeyByTemplate[currentTemplate]]
              }`,
              '_blank',
            )
          }
        />
      </div>
    ),
  })) as unknown as TableItem[];
  // link={`/${recordPathByTemplate[currentTemplate]}/${project_id}/${
  //   item[idKeyByTemplate[currentTemplate]]
  // }`}
  function handleResetSearch() {
    setFullWordSearchTable(undefined);
  }

  const fullTextSearchSelect = [
    {
      index: 'channel',
      width: 130,
      defaultValue: 'all',
      options: [
        { title: 'Все каналы', value: 'all' },
        ...(channels?.map((channel) => ({
          title: channel.name,
          value: channel.settings_channels_id,
        })) || []),
      ],
    },
    {
      index: 'role',
      width: 130,
      defaultValue: 'all',
      options: [
        { title: 'Все роли', value: 'all' },
        ...(chatRoles?.map((role) => ({
          title: role.name || '',
          value: role.crole_id || '',
        })) || []),
      ],
    },
  ];
  const apSearchExceptions =
    project[currentTemplate]?.status === AllStatusProject.ACTIVE ||
    project[currentTemplate]?.status === AllStatusProject.PAUSE;
  // search
  if (projectLoading || allProjectsLoading || projectLoadingForLast || localLoading)
    return <SkeletonPageLoading />;

  return (
    <div className="pb-[25px]">
      <PageHeader
        icon="FolderMediaIcon"
        label={project[currentTemplate || 'voice']?.name || <span>&mdash;</span>}
      >
        <div className="inline-flex items-center">
          {t('select_analytic_project.label')}:
          <div className="ml-[11px]">
            <Select
              defaultValue={currentProjectIdByTemplate || ''}
              options={getOptionsSelect()}
              placeholder={t('select_analytic_project.select_placeholder')}
              size={300}
              dropHeight={250}
              dropWidth={296}
              onChange={(value) => changeProjectSelect(value as string)}
            />
          </div>
        </div>
      </PageHeader>
      <div>
        <EasyFilterView
          dataIdStartDate="project-records-date-from"
          dataIdEndDate="project-records-date-to"
          data={filterParams.filter}
          isLoading={projectMetricLoading}
          titleMarkup={getTitleFilter}
          footerMarkup={getFooterFilter}
          onChangeHandler={handleFilterChange}
          keysForFiltering={getFilteringKeys() || []}
          disabledChangeButton={!project[currentTemplate || 'voice']?.project_id}
          filterWithSearch
          handleSubmitSearchWithFilter={onSubmitSearch}
          handleResetSearch={handleResetSearch}
          selects={
            currentTemplate === 'voice' ? [fullTextSearchSelect[0]] : [fullTextSearchSelect[1]]
          }
          disableSearch={!apSearchExceptions}
        />
        {searchLoading ? (
          <Loading />
        ) : (
          <div className="relative">
            {fullWordSearchTable && (
              <FilterSearchTable
                tableData={tableFullTextSearchData}
                tableCols={tableFullTextSearchColumns}
                handleChangePage={handleChangePage}
                offset={fullWordTextSearchRequestData?.offset || 0}
                limit={fullWordTextSearchRequestData.limit || 10}
                onFilter={tableSearchChangeFilterHandler}
              />
            )}
            <TableMetricRecords
              project_id={currentProjectIdByTemplate || ''}
              filterParams={filterParams}
              currentTemplate={currentTemplate}
              changeFilterParams={changeFilterParams}
              hide={!!fullWordSearchTable}
            />
          </div>
        )}
      </div>
    </div>
  );
});
LastProjectRecordsPage.displayName = 'LastProjectRecordsPage';
export default LastProjectRecordsPage;
