export const serverPrefix = '/api/front';
export const serverAuthPrefix = '/api/auth';

const serverRoutes = {
  me: `${serverPrefix}/me`,
  login: `${serverAuthPrefix}/login`,
  allChats: `${serverPrefix}/chat/bulk`,
  singleChat: `${serverPrefix}/chat`,
  chatGroup: `${serverPrefix}/c_project/group/bulk`,
  newChatGroup: `${serverPrefix}/c_project/group`,
  chatProject: `${serverPrefix}/c_project`,
  chatPreset: `${serverPrefix}/c_project/preset`,
  manualRecordingUpload: `${serverPrefix}/record`,
  changeAnalyticChatStatus: `${serverPrefix}/c_project/status/change`,
  getAnalyticChatStatus: `${serverPrefix}/c_project/status`,
  refresh_token: `${serverAuthPrefix}/refresh`,
  recordMetadata: `${serverPrefix}/record/metadata`,
  tenantAliases: `${serverPrefix}/alias/bulk`,
  aliases: `${serverPrefix}/cus/aliases`,
  c_aliases: `${serverPrefix}/cus/c/aliases`,
  preset: `${serverPrefix}/preset`,
  usersBulk: `${serverPrefix}/adm/user/bulk`,
  users: `${serverPrefix}/adm/user`,
  usersRole: `${serverPrefix}/adm/role/bulk`,
  projectInfo: `${serverPrefix}/project`,
  projectMetricResult: `${serverPrefix}/project/metric_result`,
  projectChatMetricResult: `${serverPrefix}/c_project/metric_result`,
  projectMetric: `${serverPrefix}/project/metric`,
  projectMetricValue: `${serverPrefix}/project/metric/value`,
  chatProjectMetricValue: `${serverPrefix}/c_project/metric/value`,
  metrics: `${serverPrefix}/project/metric_result/bulk`,
  metricsChat: `${serverPrefix}/c_project/metric_result/bulk`,
  recordList: `${serverPrefix}/record/bulk`,
  record: `${serverPrefix}/record`,
  downloadRecordTranscription: `${serverPrefix}/record/transcription/export`,
  downloadChatRecordTranscription: `${serverPrefix}/chat/transcription/export`,
  audio: `${serverPrefix}/record/audio`,
  reports: `${serverPrefix}/project/report/bulk`,
  changeStatus: `${serverPrefix}/project/report/status`,
  report: `${serverPrefix}/project/report`,
  live_reports: `${serverPrefix}/live_report/bulk`,
  chat_live_reports: `${serverPrefix}/c_live_report/bulk`,
  live_report: `${serverPrefix}/live_report`,
  chat_live_report: `${serverPrefix}/c_live_report`,
  live_report_clone: `${serverPrefix}/live_report/clone`,
  chat_live_report_clone: `${serverPrefix}/c_live_report/clone`,
  live_report_export: `${serverPrefix}/live_report/record/export`,
  live_report_records: `${serverPrefix}/live_report/record/bulk`,
  chat_live_report_records: `${serverPrefix}/c_live_report/chat/bulk`,
  projects: `${serverPrefix}/project/bulk`,
  projectsChat: `${serverPrefix}/c_project/bulk`,
  charts: `${serverPrefix}/live_report/graph/bulk`,
  chatCharts: `${serverPrefix}/c_live_report/graph/bulk`,
  chartData: `${serverPrefix}/live_report/graph/data`,
  chatChartData: `${serverPrefix}/c_live_report/graph/data`,
  chart: `${serverPrefix}/live_report/graph`,
  chatChart: `${serverPrefix}/c_live_report/graph`,
  chartLayout: `${serverPrefix}/live_report/graph/layout`,
  chatChartLayout: `${serverPrefix}/c_live_report/graph/layout`,
  metric: `${serverPrefix}/project/metric/bulk`,
  metricChat: `${serverPrefix}/c_project/metric/bulk`,
  metricProjectChat: `${serverPrefix}/c_project/metric`,
  metricProject: `${serverPrefix}/project/metric`,
  transcription: `${serverPrefix}/record/transcription`,
  recordTranscriptionModels: `${serverPrefix}/record/transcriptions`,
  audioChannel: `${serverPrefix}/settings/channel`,
  audioChannels: `${serverPrefix}/settings/channel/bulk`,
  userInfo: `${serverPrefix}/me`,
  wordsCloud: `${serverPrefix}/wordcloud`,
  audit_logs: `${serverPrefix}/audit_log/bulk`,
  audit_log: `${serverPrefix}/audit_log`,
  commonSettingsChannels: `${serverPrefix}/settings/channel/bulk`,
  commonSettingChannel: `${serverPrefix}/settings/channel`,
  aliasSettings: `${serverPrefix}/alias/bulk`,
  aliasSetting: `${serverPrefix}/alias`,
  recognition_rule_default: `${serverPrefix}/recog_rule/default`,
  recognition_rule: `${serverPrefix}/recog_rule`,
  recognition_rule_do: `${serverPrefix}/recog_rule/do`,
  recognition_rule_count: `${serverPrefix}/recog_queue/count`,
  recognition_rules: `${serverPrefix}/recog_rule/bulk`,
  asr_models: `${serverPrefix}/usr/model/bulk`,
  asr_ext_models: `${serverPrefix}/ext_asr_model/bulk`,
  asr_model: `${serverPrefix}/usr/model`,
  dashboards: `${serverPrefix}/dashboard/bulk`,
  dashboard: `${serverPrefix}/dashboard`,
  dashboardTake: `${serverPrefix}/dashboard/take`,
  dashboardGraphs: `${serverPrefix}/dashboard/graph/bulk`,
  dashboardGraph: `${serverPrefix}/dashboard/graph`,
  dashboardLayout: `${serverPrefix}/dashboard/graph/layout`,
  analyticsProjectsGroups: `${serverPrefix}/project/group/bulk`,
  analyticsProjectsGroup: `${serverPrefix}/project/group`,
  analyticsProjectsGroupsStatus: `${serverPrefix}/project/status/bulk`,
  analyticsProjectStatus: `${serverPrefix}/project/status`,
  analyticsProject: `${serverPrefix}/project`,
  analyticsProjectTake: `${serverPrefix}/project/take`,
  analyticsChatProjectTake: `${serverPrefix}/c_project/take`,
  analyticsProjectPreset: `${serverPrefix}/project/preset`,
  analyticsChatProjectPreset: `${serverPrefix}/c_project/preset`,
  analyticsProjectStatusChange: `${serverPrefix}/project/status/change`,
  presetGroups: `${serverPrefix}/preset_group/bulk`,
  presetGroup: `${serverPrefix}/preset_group`,
  metricTemplatesGroups: `${serverPrefix}/metric_template/group/bulk`,
  metricTemplatesGroup: `${serverPrefix}/metric_template/group`,
  metricTemplate: `${serverPrefix}/metric_template`,
  metricsTypes: `${serverPrefix}/metric_types/bulk`,
  chatMetricsTypes: `${serverPrefix}/chat_metric_types/bulk`,
  metricsClassifierModels: `${serverPrefix}/classifier/models`,
  dictionaries: `${serverPrefix}/dictionary/bulk`,
  dictionary: `${serverPrefix}/dictionary`,
  integrationsAccessKeys: `${serverPrefix}/access_key/bulk`,
  integrationsAccessKey: `${serverPrefix}/access_key`,
  gramCloud: `${serverPrefix}/ngramcloud`,
  gramCloudUpdate: `${serverPrefix}/ngramcloud/update`,
  gramCloudStatus: `${serverPrefix}/ngramcloud/status`,
  gramCloudData: `${serverPrefix}/ngramcloud/data`,
  gramCloudChat: `${serverPrefix}/c_ngramcloud`,
  gramCloudUpdateChat: `${serverPrefix}/c_ngramcloud/update`,
  gramCloudStatusChat: `${serverPrefix}/c_ngramcloud/status`,
  gramCloudDataChat: `${serverPrefix}/c_ngramcloud/data`,
  userSettings: `${serverPrefix}/cus/parameters`,
  billing: `${serverPrefix}/billing`,
  emailMessages: `${serverPrefix}/sender/email_queue/bulk`,
  telegramMessages: `${serverPrefix}/sender/telegram_queue/bulk`,
  telegramTenantRecipients: `${serverPrefix}/sender/telegram/recipient/bulk`,
  emailTenantRecipients: `${serverPrefix}/sender/email/recipient/bulk`,
  metricTriggerBulk: `${serverPrefix}/project/trigger/bulk`,
  metricTrigger: `${serverPrefix}/project/trigger`,
  triggerEmailRecipientNew: `${serverPrefix}/project/trigger/email_recipient/new`,
  triggerTelegramRecipientNew: `${serverPrefix}/project/trigger/telegram_recipient/new`,
  triggerEmailRecipientsBulk: `${serverPrefix}/project/trigger/email_recipient/bulk`,
  triggerTelegramRecipientsBulk: `${serverPrefix}/project/trigger/telegram_recipient/bulk`,
  triggerEmailRecipient: `${serverPrefix}/project/trigger/email_recipient`,
  triggerTelegramRecipient: `${serverPrefix}/project/trigger/telegram_recipient`,
  emailTenantRecipient: `${serverPrefix}/sender/email/recipient`,
  telegramTenantRecipient: `${serverPrefix}/sender/telegram/recipient`,
  reportTasksBulk: `${serverPrefix}/live_report/report_task/bulk`,
  reportTasks: `${serverPrefix}/live_report/report_task`,
  reportTasksEmailRecipientsBulk: `${serverPrefix}/live_report/report_task/email_recipient/bulk`,
  reportTasksTgRecipientsBulk: `${serverPrefix}/live_report/report_task/telegram_recipient/bulk`,
  reportTelegramRecipientNew: `${serverPrefix}/live_report/report_task/telegram_recipient/new`,
  reportEmailRecipientNew: `${serverPrefix}/live_report/report_task/email_recipient/new`,
  reportTelegramRecipient: `${serverPrefix}/live_report/report_task/telegram_recipient`,
  reportEmailRecipient: `${serverPrefix}/live_report/report_task/email_recipient`,
  static_report: `${serverPrefix}/static_report`,
  c_role_settings_bulk: `${serverPrefix}/settings/c_role/bulk`,
  c_role_settings: `${serverPrefix}/settings/c_role`,
  full_text_search: `${serverPrefix}/record/search`,
  full_text_search_by_project: `${serverPrefix}/project/search`,
  full_text_search_chat: `${serverPrefix}/chat/search`,
  full_text_search_by_chat_project: `${serverPrefix}/c_project/search`,
};
export default serverRoutes;
